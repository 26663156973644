import PageHeader from 'Components/PageHeader'
import ApplicationForm from 'Forms/Application/ApplicationForm'
import { useApplicationQuery, useGetOrganism } from 'models'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { useStore } from 'stores'

type ApplicationViewProps = {
  applicationViewModalId?: string
  onClose?: () => void
}

const ApplicationView = ({ applicationViewModalId, onClose }: ApplicationViewProps) => {
  const intl = useIntl()
  const store = useStore()
  const navigate = useNavigate()

  const { applicationId } = useParams()

  const isEdit = Boolean(applicationId || applicationViewModalId)

  const { organism } = useGetOrganism(store.organismId || '')

  const { data, loading: applicationLoading } = useApplicationQuery({
    variables: {
      applicationId: applicationViewModalId || (applicationId as string),
    },
    fetchPolicy: 'cache-and-network',
    skip: !applicationId && !applicationViewModalId,
  })

  return (
    <div className="flex flex-col p-5">
      <div className="p-2">
        <PageHeader title={intl.formatMessage({ id: 'application.title' }, { type: 'singular' })} />
      </div>
      <Segment>
        {organism && !applicationLoading && (
          <ApplicationForm
            isEdit={isEdit}
            organismId={organism.id}
            application={data?.application ? data.application : undefined}
            isWhiteLabel={organism.whiteLabel}
            onCancel={() => (applicationViewModalId && onClose ? onClose() : navigate(-1))}
            onDone={() => (applicationViewModalId && onClose ? onClose() : navigate(-1))}
          />
        )}
      </Segment>
    </div>
  )
}

export default ApplicationView
