export const applicationUris = {
  adCampaigns: '/ad-campaigns',
  analytics: '/analytics',
  application: (applicationId: string) => `/applications/${applicationId}`,
  applications: '/applications',
  applicationCreate: '/applications/create',
  applicationDesigns: '/application-designs',
  chatRooms: '/chat-rooms',
  contentPushStreams: '/content-push-streams',
  customPage: (customPageId: string) => `/custom-pages/${customPageId}`,
  customPages: '/custom-pages',
  customPageCreate: '/custom-pages/create',
  dashboard: '/dashboard',
  defaultConfig: '/config',
  editedCategories: '/edited-categories',
  editedCategory: (editedCategoryId: string) => `/edited-categories/${editedCategoryId}`,
  editedCategoryCreate: '/edited-categories/create',
  forgotPassword: '/forgot-password',
  frontPages: '/frontpages',
  frontPagesStats: '/frontPages-stats',
  legalRestrictions: `/legal-restrictions`,
  licensedUsers: `/licensed-users`,
  links: '/links',
  linksAnalytics: '/analytics/links',
  login: '/login',
  medias: '/medias',
  monitoring: '/monitoring',
  newss: '/news',
  news: (newsId: string) => `/news/${newsId}`,
  newsCreate: '/news/create',
  organisms: '/organisms',
  organismsFrontPages: '/organisms-front-pages',
  organismsPages: '/organisms-pages',
  playlists: '/playlists',
  playlistCreate: '/playlists/create',
  playlist: (sportItemSetId: string) => `/playlists/${sportItemSetId}`,
  playlistItems: (sportItemSetId: string) => `/playlists/${sportItemSetId}/items`,
  sportItemCreate: (sportItemSetId: string) => `/sportItem/${sportItemSetId}/create`,
  sportItem: (sportItemId: string) => `/sportItem/${sportItemId}`,
  purchasableOffers: '/purchasable-offers',
  products: (purchasableOfferId: string) => `/products/${purchasableOfferId}`,
  resetPassword: '/reset-password',
  sports: '/sports',
  sportEvents: '/sportevents',
  sportEventCreate: '/sportevents/create',
  sportEvent: (sportItemSetId: string) => `/sportevents/${sportItemSetId}`,
  sportEventItems: (sportItemSetId: string) => `/sportevents/${sportItemSetId}/items`,
  sportItemMedias: (sportItemId: string) => `/sportItem/${sportItemId}/medias`,
  sportsFrontPages: '/sports-front-pages',
  streamMap: '/stream-map',
  sveChannel: (sveChannelIdField: string) => `/channels/${sveChannelIdField}`,
  sveChannels: '/channels',
  thirdPartyApplications: '/third-party-applications',
  users: '/users',
  verifyAccount: '/verify-account',
  videoGrid: '/video-grid',
}
