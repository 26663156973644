import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { applicationUris } from 'Layout/uris'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Icon, Table } from 'semantic-ui-react'
import { ApplicationContentFragment, Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`

interface ApplicationRowProps {
  application: ApplicationContentFragment
  onRemoveApplication: () => Promise<any>
}

const ApplicationViewRow = ({ application, onRemoveApplication }: ApplicationRowProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()

  return (
    <Table.Row>
      <Table.Cell textAlign="center">{application.applicationId}</Table.Cell>
      <Table.Cell textAlign="center">{application.organism?.title}</Table.Cell>
      <Table.Cell textAlign="center">{application.title}</Table.Cell>
      <Table.Cell textAlign="center">
        <FormattedMessage id={`publicationStatus.${application.publicationStatus}`} />
      </Table.Cell>
      <Table.Cell textAlign="center">{application.type}</Table.Cell>
      <Table.Cell textAlign="center">{application.url}</Table.Cell>
      <Table.Cell textAlign="center">
        <div>
          {application.excludedContent.sportItemSets.map(({ id, title }) => (
            <div key={id}>{title}</div>
          ))}
        </div>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Buttons>
          <Link to={{ pathname: applicationUris.customPages, search: `applicationId=${application.id}` }}>
            <Icon name="sitemap" link size="large" inverted color="black" />
          </Link>
          {currentUser.can(Permission.ApplicationUpdate) && (
            <Link to={applicationUris.application(application.id)}>
              <Icon name="edit" link size="large" inverted color="black" />
            </Link>
          )}
          {currentUser.can(Permission.ApplicationDelete) && (
            <ConfirmationButton
              action={onRemoveApplication}
              confirmText={intl.formatMessage({
                id: 'application.delete_confirm',
              })}
              successText={intl.formatMessage({
                id: 'application.delete_success',
              })}
            />
          )}
        </Buttons>
      </Table.Cell>
    </Table.Row>
  )
}

export default ApplicationViewRow
