import ApplicationViewRow from 'Components/Application/ApplicationViewRow'
import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { applicationUris } from 'Layout/uris'
import { Permission, useApplicationsQuery, useDeleteApplicationMutation, useGetOrganism } from 'models'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Grid, Table } from 'semantic-ui-react'
import { useCurrentUser, useStore } from 'stores'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

const ApplicationsView = () => {
  const store = useStore()
  const intl = useIntl()

  const { organism } = useGetOrganism(store.organismId || '')
  const { data, loading, refetch } = useApplicationsQuery({
    variables: {
      filters: {
        organismId: store.organismId || undefined,
        multiOrganism: !store.organismId,
      },
    },
    fetchPolicy: 'cache-and-network',
  })
  const currentUser = useCurrentUser()

  const applications = useMemo(() => {
    const applicationsToShow = data?.applications?.applications ?? []

    if (!currentUser.can(Permission.ApplicationReadUnpublished))
      return applicationsToShow.filter(application => application.publicationStatus === 'published')

    return applicationsToShow
  }, [currentUser, data?.applications?.applications])

  const canCreateApplication = useMemo(() => {
    return currentUser.can(Permission.ApplicationCreate) && ((organism && organism.whiteLabel) || !store.organismId)
  }, [currentUser, organism, store.organismId])

  const [removeApplication] = useDeleteApplicationMutation()

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'application.title' }, { type: 'plural' })}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {canCreateApplication && (
              <Link to={applicationUris.applicationCreate}>
                <Button icon="add" circular color="red" />
              </Link>
            )}
          </PageHeader>
          <Table striped compact celled>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>
                  <FormattedMessage id="application.applicationId" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="application.organism" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="application.field.title" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="application.publicationStatus" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="application.type" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="application.url" />
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  <FormattedMessage id="application.excludedContent.sportItemSet" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="application.actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {applications && applications.length > 0 && (
                <>
                  {applications.map(application => (
                    <ApplicationViewRow
                      key={application.id}
                      application={cloneWithoutGraphqlCacheTypenames(application)}
                      onRemoveApplication={async () => {
                        await removeApplication({
                          variables: { id: application.id },
                        })
                        refetch()
                      }}
                    />
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default ApplicationsView
