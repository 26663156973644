import { FormApi, Mutator, ValidationErrors } from 'final-form'
import React, { Fragment, useCallback, useState } from 'react'
import { Form as RFForm, FormRenderProps, RenderableProps as RFRenderableProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Confirm, Dimmer, Form, Header, Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import renderComponent from 'tools/renderComponent'

const ModalForm = styled(Form)`
  margin-bottom: 1rem;
`

export interface GenericFormProps<TValues extends object> extends RFRenderableProps<FormRenderProps<TValues>> {
  header?: string
  initialValues?: Partial<TValues> | undefined
  isModal?: boolean
  mutators?: { [key: string]: Mutator<object> }
  onCancel?: () => void
  submitting?: boolean
  onSubmit: (values: TValues) => Promise<void> | void
  validate?: (values: TValues) => ValidationErrors | Promise<ValidationErrors> | undefined
  loading?: boolean
  progress?: number
  progressMessage?: string
  open?: boolean
  resetInputsAfterSubmit?: boolean
}

const GenericForm = <TValues extends object>(props: GenericFormProps<TValues>) => {
  const {
    initialValues,
    mutators,
    onCancel,
    onSubmit,
    validate,
    header,
    isModal = true,
    loading,
    progress,
    progressMessage,
    render,
    children,
    component,
    open = true,
    resetInputsAfterSubmit = false,
  } = props

  const intl = useIntl()
  const [confirm, setConfirm] = useState<JSX.Element | null>()
  const Wrapper = isModal ? Modal : Form
  const TitleHeader = isModal ? Modal.Header : Header
  const handleOnSubmit = useCallback((values: object) => onSubmit(values as TValues), [onSubmit])

  const onCancelClick = useCallback(
    (pristine: boolean, form: FormApi<TValues>) => {
      if (pristine) {
        if (onCancel) onCancel()
        return
      }
      setConfirm(
        <Confirm
          size="tiny"
          open
          // header="Annulation"
          content={intl.formatMessage({ id: 'common.cancel.sentence' })}
          cancelButton={{ content: intl.formatMessage({ id: 'common.no' }) }}
          confirmButton={{
            content: intl.formatMessage({ id: 'common.yes' }),
            negative: true,
          }}
          onConfirm={() => {
            setConfirm(null)
            form.reset()
            if (onCancel) onCancel()
          }}
          onCancel={() => setConfirm(null)}
        />,
      )
    },
    [intl, onCancel],
  )
  const validateForm = useCallback(
    async (values: object) => validate && (await validate(values as TValues)),
    [validate],
  )
  const submitAndCloseForm = async (submit: () => Promise<any> | undefined, form: FormApi<object, object>) => {
    await submit()
    form.reset()
  }
  return (
    <Fragment>
      <RFForm
        onSubmit={handleOnSubmit}
        keepDirtyOnReinitialize={resetInputsAfterSubmit}
        validate={validateForm}
        initialValues={initialValues}
        mutators={mutators}
        render={formState => {
          const { handleSubmit, pristine, invalid, form } = formState

          return (
            <Wrapper mountNode={document.getElementById('modals')} open={open}>
              {header && <TitleHeader>{header}</TitleHeader>}
              {/* Note: Dimmer will cover also Modal.Actions & Modal.Header */}
              <Modal.Content scrolling>
                <Dimmer.Dimmable dimmed={loading}>
                  <Dimmer active={loading} inverted />
                  <ModalForm
                    onSubmit={(e: React.SyntheticEvent<HTMLFormElement, Event>) =>
                      submitAndCloseForm(() => handleSubmit(e), form)
                    }
                  >
                    {renderComponent<FormRenderProps<any>>({ ...formState, render, children, component })}
                  </ModalForm>
                </Dimmer.Dimmable>
              </Modal.Content>
              <div className="text-right p-4 flex-end sticky bottom-0 bg-white z-10">
                {typeof progress === 'number' && (
                  <>
                    {!!progressMessage && progressMessage.trim() !== '' && <strong>{progressMessage}: </strong>}
                    <strong>{progress} %</strong>
                  </>
                )}

                <Button
                  negative
                  disabled={!onCancel || loading}
                  onClick={() => onCancelClick(pristine, form as any as FormApi<TValues>)}
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                  positive
                  loading={loading}
                  disabled={invalid || loading}
                  onClick={() => submitAndCloseForm(() => handleSubmit(), form)}
                >
                  <FormattedMessage id="common.submit" />
                </Button>
              </div>
            </Wrapper>
          )
        }}
      />
      {confirm}
    </Fragment>
  )
}

export default GenericForm
