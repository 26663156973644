import { applicationUris } from 'Layout/uris'

import ApplicationView from 'Views/ApplicationView'
import ApplicationsView from 'Views/ApplicationsView'
import CustomPageView from 'Views/CustomPageView'
import CustomPagesView from 'Views/CustomPagesView'
import EditedCategoriesView from 'Views/EditedCategoriesView'
import EditedCategoryView from 'Views/EditedCategoryView'
import FrontPagesStatsView from 'Views/FrontPagesStatsView'
import FrontPagesView from 'Views/FrontPagesView'
import OrganismsPages from 'Views/OrganismsPages/OrganismsPages'
import React from 'react'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

const editorialRoutes = (user: IUser | null) => [
  {
    path: applicationUris.frontPages,
    element: <FrontPagesView />,
    loader: () => userCanAccessToPath(user, [Access.FrontPages]),
  },
  {
    path: applicationUris.frontPagesStats,
    element: <FrontPagesStatsView />,
    loader: () => userCanAccessToPath(user, [Access.FrontPages]),
  },
  {
    path: applicationUris.editedCategories,
    element: <EditedCategoriesView />,
    loader: () => userCanAccessToPath(user, [Access.EditedCategories]),
  },
  {
    path: applicationUris.editedCategory(':editedCategoryId'),
    element: <EditedCategoryView />,
    loader: () => userCanAccessToPath(user, [Access.EditedCategories]),
  },
  {
    path: applicationUris.editedCategoryCreate,
    element: <EditedCategoryView />,
    loader: () => userCanAccessToPath(user, [Access.EditedCategories]),
  },
  {
    path: applicationUris.applications,
    element: <ApplicationsView />,
    loader: () => userCanAccessToPath(user, [Access.ApplicationView]),
  },
  {
    path: applicationUris.application(':applicationId'),
    element: <ApplicationView />,
    loader: () => userCanAccessToPath(user, [Access.ApplicationView]),
  },
  {
    path: applicationUris.applicationCreate,
    element: <ApplicationView />,
    loader: () => userCanAccessToPath(user, [Access.ApplicationView]),
  },
  {
    path: applicationUris.customPages,
    element: <CustomPagesView />,
    loader: () => userCanAccessToPath(user, [Access.CustomPagesView]),
  },
  {
    path: applicationUris.customPage(':customPageId'),
    element: <CustomPageView />,
    loader: () => userCanAccessToPath(user, [Access.CustomPagesView]),
  },
  {
    path: applicationUris.customPageCreate,
    element: <CustomPageView />,
    loader: () => userCanAccessToPath(user, [Access.CustomPagesView]),
  },
  {
    path: applicationUris.organismsPages,
    element: <OrganismsPages />,
    loader: () => userCanAccessToPath(user, [Access.OrganismPagesView]),
  },
]

export default editorialRoutes
